






























import Vue from "vue";
import Component from "vue-class-component";
import Banner from "@/components/Banner.vue";
import HeaderTitle from "@/components/HeaderTitle.vue";

@Component({
  components: {
    Banner,
    HeaderTitle,
  },
})
export default class ApplicationCompletionNotice extends Vue {}
