





















import Vue from "vue";
import Component from "vue-class-component";
import CustomAppBar from "@/components//CustomAppBar.vue";

@Component({
  components: {
    CustomAppBar,
  },
})
export default class App extends Vue {}
