








import Vue from "vue";
import Component from "vue-class-component";

const HeaderTitleProps = Vue.extend({
  props: {
    title: String,
  },
});

@Component
export default class HeaderTitle extends HeaderTitleProps {}
