
















































































import {
  ApplicationControllerApi,
  ApplicationRegistrationRequest,
} from "@/apis/lms";
import Vue from "vue";
import Component from "vue-class-component";
import Banner from "@/components/Banner.vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
import Dialogue from "@/components/Dialogue.vue";

@Component({
  components: {
    Banner,
    HeaderTitle,
    Dialogue,
  },
})
export default class ApplicationRequest extends Vue {
  api = new ApplicationControllerApi();
  request: ApplicationRegistrationRequest = {
    email: "",
  };

  loading = false;
  raiseNotice = false;
  error = false;
  emailError = "";

  async requestApplication() {
    this.loading = true;
    try {
      await this.api.getRegistrationToken(this.request);
      this.raiseNotice = true;
      this.error = false;
      this.emailError = "";
    } catch (e) {
      const message = e.response.data;
      if (message.type && message.type == "shallow") {
        this.emailError = message.error;
      } else {
        this.error = true;
        this.raiseNotice = true;
      }
    } finally {
      this.loading = false;
    }
  }

  validate(key: string) {
    this.emailError = "";
  }
}
