import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Home from "@/components/Home.vue";
import ParrotRegistration from "@/components/ParrotRegistration.vue";
import LicenceApplicationForm from "@/components/LicenceApplicationForm.vue";
import ApplicationRequest from "@/components/ApplicationRequest.vue";
import ApplicationRenewal from "@/components/ApplicationRenewal.vue";
import ApplicationCompletionNotice from "@/components/ApplicationCompletionNotice.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/register-parrot",
    component: ParrotRegistration,
  },
  {
    path: "/application-request",
    component: ApplicationRequest,
  },
  {
    path: "/licence-application/:token",
    component: LicenceApplicationForm,
  },
  {
    path: "/application-renewal",
    component: ApplicationRenewal,
  },
  {
    path: "/application-completion",
    component: ApplicationCompletionNotice,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
