































































































import {
  ApplicationControllerApi,
  ApplicationRenewalRequest,
  IdentificationType,
} from "@/apis/lms";
import Vue from "vue";
import Component from "vue-class-component";
import Banner from "@/components/Banner.vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
import Dialogue from "@/components/Dialogue.vue";

@Component({
  components: {
    Banner,
    HeaderTitle,
    Dialogue,
  },
})
export default class ApplicationRenewal extends Vue {
  api = new ApplicationControllerApi();
  request: ApplicationRenewalRequest = this.initForm();

  idTypes = Object.values(IdentificationType);

  loading = false;
  raiseNotice = false;
  error = false;
  errorMessages = this.initErrors();
  shallowError = "";

  initErrors() {
    const keys = Object.keys(this.initForm());
    return keys
      .map((key) => ({ [key]: "" }))
      .reduce((prev, cur) => ({ ...prev, ...cur }));
  }

  initForm() {
    return {
      idType: IdentificationType.IdentificationCard,
      idNumber: "",
      email: "",
      oldLicenceNumber: "",
    };
  }

  validate(key: string) {
    this.errorMessages[key] = "";
  }

  async renewLicence() {
    this.loading = true;
    try {
      this.errorMessages = this.initErrors();
      await this.api.getRenewalApplicationToken(this.request);
      this.error = false;
    } catch (e) {
      console.log(e);
      this.error = true;
      const message = e.response.data;
      if (message.type && message.type == "deep") {
        const errors = JSON.parse(message.error);
        this.errorMessages = errors[0]["request"];
      } else if (message.type && message.type == "shallow") {
        this.shallowError = message.error;
        this.raiseNotice = true;
      } else {
        this.raiseNotice = true;
      }
    } finally {
      this.loading = false;
    }
  }
}
