






































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import {
  IdentificationType,
  ParrotRegisterPost,
  AnimalControllerApi,
  EscapePrevention,
  HealthCareType,
  AgeGroup,
  ParrotGet,
} from "@/apis/lms";
import Banner from "@/components/Banner.vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
import Dialogue from "@/components/Dialogue.vue";

function useForm(): ParrotRegisterPost {
  return {
    areaOfResidence: String(),
    contactNumber: String(),
    escapePrevention: EscapePrevention.NotNecessary,
    fullName: String(),
    healthCareType: HealthCareType.SelfCare,
    idNumber: String(),
    idType: IdentificationType.DriverLicence,
    nameOfPet: String(),
    photo: "",
    regionOfResidence: Number(),
    stage: AgeGroup.Baby,
    yearAcquired: Number(2018),
  };
}

function initErrors() {
  const keys = Object.keys(useForm()).concat(["parrot"]);
  return keys
    .map((key) => ({ [key]: "" }))
    .reduce((prev, cur) => ({ ...prev, ...cur }));
}

@Component({
  components: {
    Banner,
    HeaderTitle,
    Dialogue,
  },
})
export default class ParrotRegistration extends Vue {
  api = new AnimalControllerApi();

  form = useForm();
  parrots: ParrotGet[] = [];
  parrot: ParrotGet = this.initParrot();

  loading = false;
  raiseNotice = false;
  showParrots = false;
  error = false;

  keys = Object.keys(useForm());

  errorMessages = initErrors();

  get IDENTIFICATION_TYPES() {
    return Object.values(IdentificationType);
  }

  get AGE_GROUPS() {
    return Object.values(AgeGroup);
  }

  get ESCAPE_PREVENTIONS() {
    return Object.values(EscapePrevention);
  }

  get HEALTHCARE_TYPES() {
    return Object.values(HealthCareType);
  }

  validate(key: string) {
    this.errorMessages[key] = "";
  }

  async mounted() {
    const response = await this.api.getParrots(this.form);
    this.parrots = [this.initParrot(), ...response.data];
  }

  initParrot() {
    return {
      id: 0,
      scientificName: "My pet is not on the list",
      commonNames: [],
      photo: "",
    };
  }

  onPhotoInput(file: File | null) {
    if (file === null) {
      this.form.photo = String();
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () =>
      (this.form.photo = String(reader.result).split(",")[1]);
    reader.readAsDataURL(file);
    this.validate("photo");
  }

  async registerParrot() {
    const form: any = this.$refs["form"];
    if (!form.validate()) return;
    this.errorMessages = initErrors();
    this.loading = true;
    try {
      await this.api.registerParrot(this.form);
      this.error = false;
      setTimeout(() => this.$router.push("/"), 5000);
    } catch (e) {
      const message = e.response.data;
      if (message.type && message.type == "deep") {
        const errors = JSON.parse(message.error);
        this.errorMessages = errors[0]["register"];
      }
      this.error = true;
    } finally {
      this.raiseNotice = true;
      this.loading = false;
    }
    //}
  }

  selectParrot(parrot: ParrotGet) {
    this.parrot = parrot;
    this.form.parrotId = undefined;
    this.form.otherParrot = undefined;
    if (this.parrot.id == 0) {
      if (this.parrot.commonNames && this.parrot.commonNames.length == 1) {
        this.form.otherParrot = this.parrot.commonNames[0].toLowerCase();
      }
    } else {
      this.form.parrotId = this.parrot.id;
    }
    this.showParrots = false;
  }

  // fullNameRules: ValidationRules<string> = [
  //   (v) => Boolean(v) || "Full name required",
  // ];

  // contactNumberRules: ValidationRules<string> = [
  //   (v) => Boolean(v) || "Contact number required",
  // ];

  // emailRules: ValidationRules<string> = [
  //   (v) => v !== undefined && v.length === 0 || isEmail(v) || "Invalid email",
  // ];

  // yearAcquiredRules: ValidationRules<number> = [
  //   (v) => Boolean(v) || "Year required",
  // ];

  // administrativeRegionRule: ValidationRule<number> = (v) =>
  //   (v > 0 && v <= 10) || "Invalid admisistrative region";

  // regionOfResidenceRules: ValidationRules<number> = [
  //   this.administrativeRegionRule,
  // ];

  // areaOfResidenceRules: ValidationRules<string> = [
  //   (v) => Boolean(v) || "Invalid area of residence",
  // ];

  // regionOfOriginRules: ValidationRules<number> = [
  //   (v) => v === 0 || this.administrativeRegionRule(v),
  // ];
}
