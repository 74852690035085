























import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    title: String,
    details: String,
    errorDetails: String,
    errorTitle: {
      type: String,
      default: "System Error",
    },
    error: Boolean,
  },
})
export default class Dialogue extends Vue {
  close() {
    this.$emit("close");
  }
}
