


















import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    title: String,
  },
})
export default class Bannder extends Vue {}
