








































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class CustomAppBar extends Vue {
  home = { text: "Home", route: "/" };
  links = [
    { text: "Parrot Registration", route: "/register-parrot" },
    { text: "Licence Application", route: "/application-request" },
    // { text: "Update Licence", route: "/application-renewal" },
  ];
  mini = false;

  to(route: string) {
    this.$router.push(route);
  }
}
