



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  ApplicationControllerApi,
  ApplicationPost,
  Gender,
  IdentificationType,
  LicenceType,
  CategoryRequestPost,
  FirearmPost,
  HarvestAreaPost,
  StockPost,
  LicenceControllerApi,
  ConvictionPost,
  WildlifeCategoryControllerApi,
  WildlifeCategoryGet,
  LicenceTypeControllerApi,
  SpeciesRequestPost,
} from "@/apis/lms";
import Vue from "vue";
import Component from "vue-class-component";
import Banner from "@/components/Banner.vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
import Dialogue from "@/components/Dialogue.vue";
import axios from "axios";
import { BASE_PATH } from "@/apis/lms/base";
import router from "@/router";
import { filter } from "vue/types/umd";

enum ClientType {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}

interface Client {
  type: ClientType;
  name: string;
}

interface ApplicationFormPost {
  details: string;
  identification: File | null;
  photo: File | null;
  tin: File | null;
  firearmOne: File | null;
  firearmTwo: File | null;
  businessRegistration: File | null;
  businessIncorporation: File | null;
  noticeOfDirectors: File | null;
  articlesOfIncorporation: File | null;
  foodHandlersCertificate: File | null;
}

interface SpeciesForm {
  category: string;
  species: string;
  speciesId: number;
  unknownSpecies: boolean;
}

@Component({
  components: {
    Banner,
    HeaderTitle,
    Dialogue,
  },
})
export default class LicenceApplicationForm extends Vue {
  countries = [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];

  show = {
    custody: false,
    customers: false,
    foodHandlers: false,
    convictions: false,
    suppliers: false
  };

  appForm: ApplicationFormPost = {
    details: "",
    identification: null,
    photo: null,
    tin: null,
    firearmOne: null,
    firearmTwo: null,
    businessRegistration: null,
    businessIncorporation: null,
    noticeOfDirectors: null,
    articlesOfIncorporation: null,
    foodHandlersCertificate: null,
  };
  app: ApplicationPost = this.defaultApplication();
  api = new ApplicationControllerApi(undefined, BASE_PATH, axios);
  licenceApi = new LicenceControllerApi();
  licenceTypeApi = new LicenceTypeControllerApi();
  categoryApi = new WildlifeCategoryControllerApi();

  categoryHeaders = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Category", value: "category", sortable: false },
    { text: "Species", value: "species", sortable: false },
  ];

  harvestAreaHeaders = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Region", value: "region", sortable: false },
    { text: "Area/Village", value: "area", sortable: false },
  ];

  wildlifeInCustodyHeaders = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Species", value: "species", sortable: false },
    { text: "Dead", value: "dead", sortable: false },
    { text: "Alive", value: "alive", sortable: false },
  ];

  firearmHeaders = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Firearm Type", value: "type", sortable: false },
    { text: "Licence Number", value: "licenceNumber", sortable: false },
    { text: "Issued On", value: "issuedOn", sortable: false },
    { text: "Expires On", value: "expiresOn", sortable: false },
  ];

  clientHeaders = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Indiviual/Business", value: "type", sortable: false },
    { text: "Name", value: "name", sortable: false },
  ];

  convictionHeaders = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Year", value: "year", sortable: false },
    { text: "Region", value: "region", sortable: false },
    { text: "Area/Village", value: "area", sortable: false },
    { text: "Description", value: "description", sortable: false },
  ];

  genders = Object.values(Gender);
  idTypes = Object.values(IdentificationType);
  licenceTypes: LicenceType[] = [];
  clientTypes = Object.values(ClientType);
  categories: WildlifeCategoryGet[] = [];

  isAccepted = false;

  speciesForm: SpeciesForm = {
    category: "",
    species: "",
    speciesId: 0,
    unknownSpecies: false,
  };

  firearmForm: FirearmPost = {
    type: "",
    licenceNumber: "",
    issuedOn: "",
    expiresOn: "",
  };

  harvestAreaForm: HarvestAreaPost = {
    region: 1,
    area: "",
  };

  custodyForm: StockPost = {
    species: "",
    dead: 0,
    alive: 0,
  };

  clientForm: Client = {
    type: ClientType.INDIVIDUAL,
    name: "",
  };

  supplierForm: Client = {
    type: ClientType.INDIVIDUAL,
    name: "",
  };

  convictionForm: ConvictionPost = {
    year: 0,
    region: 1,
    area: "",
    description: "",
  };

  clients: Client[] = [];
  suppliers: Client[] = [];

  selectedSpecies: SpeciesForm[] = [];

  loading = false;

  id = 0;
  token = "";

  raiseNotice = false;
  error = false;
  errorDetails =
    "An error has occured, please check the form and make necessary corrections";
  errorTitle = "Application Error";

  errorMessages = this.initErrors();

  async mounted() {
    this.token = this.$route.params.token;
    this.id = JSON.parse(atob(this.token.split(".")[1]))["resource"];
    const response = await this.licenceTypeApi.getLicenceTypes();
    this.licenceTypes = response.data;
    this.app = await this.initApplication();

    const categoryResponse = await this.categoryApi.getCategories();
    this.categories = categoryResponse.data;
  }

  get categoryNames() {
    return this.categories.map((category) => category.category).sort();
  }

  get categorySpecies() {
    return this.categories
      .filter((item) => item.category == this.speciesForm.category)
      .filter((item) => item.species)
      .flatMap((item) => item.species)
      .filter((species) => species?.available)
      .flatMap((species) =>
        species?.commonNames
          .map((name) => ({
            text: name,
            value: `${name}~${species.id}`,
            label: species.scientificName,
          }))
          .sort((a, b) => a.text.localeCompare(b.text))
      );
  }

  get isRecreational() {
    return (
      this.app.licenceType ==
      this.licenceTypes.find((l) => l.name == "WILDLIFE_RECREATIONAL_HUNTER")?.id
    );
  }

  get isCommercial() {
    return (
      this.app.licenceType ==
      this.licenceTypes.find((l) => l.name == "WILDLIFE_COMMERCIAL")?.id
    );
  }

  required(input: string) {
    return input && input.length > 0 ? true : "This field is required";
  }

  fileRequired(input: File) {
    return input && input.size > 1024 ? true : "required";
  }

  firearmIssue() {
    const today = new Date();
    let result = this.required(this.firearmForm.issuedOn);
    if (result === true) {
      const issuedOn = new Date(this.firearmForm.issuedOn);
      result =
        today.getTime() > issuedOn.getTime()
          ? true
          : "Date must be in the past";
    }
    return result;
  }

  firearmExpiresOn() {
    const today = new Date();
    let result = this.required(this.firearmForm.expiresOn);
    if (result === true) {
      const expiresOn = new Date(this.firearmForm.expiresOn);
      result =
        expiresOn.getTime() > today.getTime() ? true : "Must be a future date";
      if (result === true) {
        const issuedOn = new Date(this.firearmForm.issuedOn);
        result =
          expiresOn.getTime() > issuedOn.getTime()
            ? true
            : "Must be after date of issuance";
      }
    }
    return result;
  }

  initErrors() {
    const keys = Object.keys(this.defaultApplication());
    const applicationErrors = keys
      .map((key) => ({ [key]: "" }))
      .reduce((prev, cur) => ({ ...prev, ...cur }));
    return { application: applicationErrors };
  }

  validate(objKey: string, key: string) {
    if (this.errorMessages[objKey]) {
      return this.errorMessages[objKey][key];
    }
    return "";
  }

  resetValidation(objKey: string, key: string) {
    if (this.errorMessages[objKey]) {
      this.errorMessages[objKey][key] = "";
    }
  }

  async initApplication(): Promise<ApplicationPost> {
    var app = this.defaultApplication();
    // if (this.id > 0) {
    //   const response = await this.api.getApplicationFromToken(this.token);
    //   app = response.data;
    // }
    return app;
  }

  defaultApplication(): ApplicationPost {
    return {
      address: "",
      countryOfResidence: "Guyana",
      firstName: "",
      lastName: "",
      resident: true,
      nationality: "Guyanese",
      community: "",
      region: 1,
      homePhone: "",
      species: [],
      harvestAreas: [],
      firearms: [],
      currentStock: [],
      customerClients: [],
      businessClients: [],
      convictions: [],
      dob: "",
      gender: Gender.Male,
      idType: IdentificationType.IdentificationCard,
      idNumber: "",
      tin: "",
      licenceType: 1,
      purpose: "",
      forBusiness: false,
      preparedByApplicant: true,
    };
  }

  async submit() {
    if (!this.isAccepted) return;
    const form: any = this.$refs.app;
    if (!form.validate()) {
      this.raiseNotice = true;
      this.error = true;
      return;
    }
    this.loading = true;
    const formData = this.loadFormData();
    const token = this.$route.params.token;
    try {
      const response = await axios({
        method: "post",
        url: `${BASE_PATH}/api/v1/applications/registration/${token}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      this.error = false;
      setTimeout(() => this.$router.push("/application-completion"), 4000);
    } catch (e) {
      this.error = true;
      const message = e.response.data;
      if (message.type == "deep") {
        const appErrors = JSON.parse(message.error)[0];
        this.errorMessages = appErrors;
      } else if (message.type == "shallow") {
        if (e.response.status == 401) {
          this.$router.push("/application-request");
        }
      }
    } finally {
      this.loading = false;
      this.raiseNotice = true;
    }
  }

  mapSpecies() {
    this.app.species = this.selectedSpecies.map((item) => {
      var data: SpeciesRequestPost = {};
      if (item.unknownSpecies) data.other = item.species;
      else data.speciesId = item.speciesId;
      return data;
    });
  }

  removeOptionalSegments() {
    if (!this.show.customers) {
      this.app.customerClients = [];
      this.app.businessClients = [];
    }
    if (!this.show.custody) this.app.currentStock = [];
    if (!this.show.convictions) this.app.convictions = [];
  }

  loadFormData() {
    this.removeOptionalSegments();
    this.mapSpecies();
    const form = new FormData();
    form.append("details", JSON.stringify(this.app));
    form.append("tin", this.appForm.tin as File);
    form.append("identification", this.appForm.identification as File);
    form.append("photo", this.appForm.photo as File);
    if (this.app.firearms) {
      if (this.app.firearms.length > 0)
        form.append("firearmOne", this.appForm.firearmOne as File);
      if (this.app.firearms.length > 1)
        form.append("firearmTwo", this.appForm.firearmTwo as File);
    }
    if (this.app.businessRegistrationNumber)
      form.append(
        "businessRegistration",
        this.appForm.businessRegistration as File
      );
    if (this.app.certificateOfIncorporationNumber) {
      form.append(
        "businessIncorporation",
        this.appForm.businessIncorporation as File
      );
      form.append("noticeOfDirectors", this.appForm.noticeOfDirectors as File);
      form.append(
        "articlesOfIncorporation",
        this.appForm.articlesOfIncorporation as File
      );
    }
    if (this.appForm.foodHandlersCertificate) {
      form.append(
        "foodHandlersCertificate",
        this.appForm.foodHandlersCertificate as File
      );
    }
    return form;
  }

  clearFormValidation(formName: string) {
    const form: any = this.$refs[formName];
    form.resetValidation();
  }

  addSpecies() {
    const form: any = this.$refs.speciesForm;
    if (!this.selectedSpecies || !form.validate()) return;
    const newEntry = { ...this.speciesForm };
    const species = this.speciesForm.species;
    if (species.includes("~")) {
      const parts = this.speciesForm.species.split("~");
      newEntry.species = parts[0];
      newEntry.speciesId = Number(parts[1]);
    }
    if (newEntry.unknownSpecies) {
      newEntry.category = "UNKNOWN SPECIES";
      newEntry.speciesId = 0;
    }
    const exists1 = this.selectedSpecies.find(
      (item) => !item.unknownSpecies && item.speciesId == newEntry.speciesId
    );
    const exists2 = this.selectedSpecies.find(
      (item) =>
        item.unknownSpecies &&
        item.species.toLowerCase() == newEntry.species.toLowerCase()
    );
    if (exists1 || exists2) {
      this.speciesForm.species = "";
      form.resetValidation();
      return;
    }
    this.selectedSpecies.push(newEntry);
    this.speciesForm.species = "";
    form.resetValidation();
    this.resetValidation("application", "species");
  }

  removeSpecies(i: number) {
    if (!this.selectedSpecies) return;
    this.selectedSpecies = this.selectedSpecies.filter(
      (_, index) => index != i
    );
  }

  addFirearm() {
    const form: any = this.$refs.firearmForm;
    if (!this.app.firearms || !form.validate()) return;
    const newEntry = { ...this.firearmForm };
    this.app.firearms.push(newEntry);
    this.firearmForm = {
      type: "",
      expiresOn: "",
      issuedOn: "",
      licenceNumber: "",
    };
    form.resetValidation();
  }

  removeFirearm(i: number) {
    if (this.app.firearms)
      this.app.firearms = this.app.firearms.filter((_, index) => index != i);
  }

  addHarvestArea() {
    const form: any = this.$refs.harvestForm;
    if (!this.app.harvestAreas || !form.validate()) return;
    const newEntry = { ...this.harvestAreaForm };
    this.app.harvestAreas.push(newEntry);
    this.harvestAreaForm.area = "";
    form.resetValidation();
    this.resetValidation("application", "harvestAreas");
  }

  removeHarvestArea(i: number) {
    if (this.app.harvestAreas)
      this.app.harvestAreas = this.app.harvestAreas.filter(
        (_, index) => index != i
      );
  }

  addCustody() {
    const form: any = this.$refs.custodyForm;
    if (!this.app.currentStock || !form.validate()) return;
    const newEntry = { ...this.custodyForm };
    this.app.currentStock.push(newEntry);
    this.custodyForm = {
      alive: 0,
      dead: 0,
      species: "",
    };
    form.resetValidation();
  }

  removeCustody(i: number) {
    if (this.app.currentStock)
      this.app.currentStock = this.app.currentStock.filter(
        (_, index) => index != i
      );
  }

  addClient() {
    const form: any = this.$refs.clientForm;
    if (!form.validate()) return;
    const newEntry = { ...this.clientForm };
    this.clients.push(newEntry);
    this.populateClientLists();
    this.clientForm.name = "";
    form.resetValidation();
  }

  populateClientLists() {
    this.app.customerClients = [];
    this.app.businessClients = [];
    this.clients.forEach((client) => {
      if (client.type == ClientType.INDIVIDUAL)
        this.app.customerClients?.push(client.name);
      else this.app.businessClients?.push(client.name);
    });
  }

  removeSupplier(i: number) {
    this.suppliers = this.suppliers.filter((_, index) => index != i);
    this.populateSupplierLists();
  }

  addSupplier() {
    const form: any = this.$refs.supplierForm;
    if (!form.validate()) return;
    const newEntry = { ...this.supplierForm };
    this.suppliers.push(newEntry);
    this.populateSupplierLists();
    this.supplierForm.name = "";
    form.resetValidation();
  }

  populateSupplierLists() {
    this.app.individualSuppliers = [];
    this.app.businessSuppliers = [];
    this.suppliers.forEach((supplier) => {
      if (supplier.type == ClientType.INDIVIDUAL)
        this.app.individualSuppliers?.push(supplier.name);
      else this.app.businessSuppliers?.push(supplier.name);
    });
  }

  removeClient(i: number) {
    this.suppliers = this.suppliers.filter((_, index) => index != i);
    this.populateSupplierLists();
  }

  addConviction() {
    const form: any = this.$refs.convictionForm;
    if (!this.app.convictions || !form.validate()) return;
    const newEntry = { ...this.convictionForm };
    this.app.convictions.push(newEntry);
    this.convictionForm = {
      year: 0,
      region: 1,
      area: "",
      description: "",
    };
    form.resetValidation();
  }

  removeConviction(i: number) {
    if (!this.app.convictions) return;
    this.app.convictions = this.app.convictions.filter(
      (_, index) => index != i
    );
  }
}
